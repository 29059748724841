import React, { useState, useEffect } from 'react'
import { getAuth } from 'firebase/auth'
import { ref, onValue, update } from 'firebase/database'
import { db } from '../firebase/firebase'
import { resendVerifyEmail } from '../actions'
import { makeStyles } from '@mui/styles'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  Skeleton,
  IconButton,
  TextField,
  Snackbar,
  Alert,
  MenuItem,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit'
import emptyobject from './emptyobject.json'

const columns = [
  { id: 'jumpNr', label: 'Nr.', minWidth: 30 },
  { id: 'date', label: 'Date', minWidth: 80 },
  { id: 'location', label: 'Location', minWidth: 170 },
  { id: 'aircraft', label: 'Aircraft', minWidth: 150 },
  { id: 'canopy', label: 'Canopy', minWidth: 100 },
  { id: 'altitude', label: 'Altitude', minWidth: 60 },
  { id: 'maneuver', label: 'Maneuver', minWidth: 80 },
  { id: 'groupSize', label: 'Group Size', minWidth: 40 },
  { id: 'signature', label: 'Signature', minWidth: 100 },
]

function createData(
  did,
  jumpNr,
  date,
  location,
  aircraft,
  canopyType,
  canopySize,
  altitude,
  maneuver,
  groupSize,
  signature
) {
  let canopy = canopyType + ' ' + canopySize

  return {
    did,
    jumpNr,
    date,
    location,
    aircraft,
    canopy,
    canopyType,
    canopySize,
    altitude,
    maneuver,
    groupSize,
    signature,
  }
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: '56px',
  },
  container: {
    maxHeight: '100%',
  },
  image: {
    maxHeight: 25,
    marginTop: -5,
    marginBottom: -5,
  },
})

function List() {
  const [logbook, setLogbook] = useState([])
  const auth = getAuth()
  const userId = auth.currentUser?.auth?.currentUser?.uid
  const verified = auth.currentUser?.emailVerified

  const [aircraftOptions, setAircraftOptions] = useState([])
  const [maneuverOptions, setManeuverOptions] = useState([])

  useEffect(() => {
    const userRef = ref(db, '/users/' + userId + '/logbook/')
    onValue(userRef, (snapshot) => {
      const data = snapshot.val()
      if (data !== null) {
        setLogbook(data)
      }
    })

    const getManeuverOptions = () => {
      const dbref = ref(db, '/users/' + userId + '/maneuvers')
      onValue(dbref, (snapshot) => {
        const data = snapshot.val()
        const temp = []
        if (data !== null) {
          Object.values(data).map((i) => temp.push(i))
          setManeuverOptions(temp)
        }
      })
    }

    const getAircraftOptions = () => {
      const dbref = ref(db, '/users/' + userId + '/aircrafts')
      onValue(dbref, (snapshot) => {
        const data = snapshot.val()
        const temp = []
        if (data !== null) {
          Object.values(data).map((i) => temp.push(i))
          setAircraftOptions(temp)
        }
      })
    }

    getManeuverOptions()
    getAircraftOptions()
  }, [userId])

  useEffect(() => {}, [logbook])

  const emptyRows = Object.keys(emptyobject)
    .sort((a, b) => emptyobject[b].jumpNr - emptyobject[a].jumpNr)
    .map((i) =>
      createData(
        i,
        emptyobject[i].jumpNr,
        emptyobject[i].date,
        emptyobject[i].location,
        emptyobject[i].aircraft,
        emptyobject[i].canopyType,
        emptyobject[i].canopySize,
        emptyobject[i].altitude,
        emptyobject[i].maneuver,
        emptyobject[i].groupSize,
        emptyobject[i].signature
      )
    )

  const rows = Object.keys(logbook)
    .sort((a, b) => logbook[b].jumpNr - logbook[a].jumpNr)
    .map((i) =>
      createData(
        i,
        logbook[i].jumpNr,
        logbook[i].date,
        logbook[i].location,
        logbook[i].aircraft,
        logbook[i].canopyType,
        logbook[i].canopySize,
        logbook[i].altitude,
        logbook[i].maneuver,
        logbook[i].groupSize,
        logbook[i].signature
      )
    )

  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function smallOrBigSignature(sign) {
    if (sign.length > 50) {
      return <img className={classes.image} src={sign} alt='signature' />
    } else {
      return sign
    }
  }

  const [editIndex, setEditIndex] = useState(-1)
  const [currentDid, setCurrentDid] = useState(-1)
  const [date, setDate] = useState('')
  const [aircraft, setAircraft] = useState('')
  const [canopyType, setCanopyType] = useState('')
  const [canopySize, setCanopySize] = useState('')
  const [altitude, setAltitude] = useState('')
  const [maneuver, setManeuver] = useState('')
  const [groupSize, setGroupSize] = useState('')
  const [errorSnack, setErrorSnack] = useState(false)
  const [successSnack, setSuccessSnack] = useState(false)

  const handleCloseSnack = () => {
    setSuccessSnack(false)
    setErrorSnack(false)
  }

  const startEditing = (
    i,
    did,
    date,
    aircraft,
    canopyType,
    canopySize,
    altitude,
    maneuver,
    groupSize
  ) => {
    setCurrentDid(did)
    setDate(date)
    setAircraft(aircraft)
    setCanopyType(canopyType)
    setCanopySize(canopySize)
    setAltitude(altitude)
    setManeuver(maneuver)
    setGroupSize(groupSize)
    setEditIndex(i)
  }

  const stopEditing = () => {
    const dbref = ref(db, 'users/' + userId + '/logbook/' + currentDid)

    update(dbref, {
      date: date,
      aircraft: aircraft,
      canopyType: canopyType,
      canopySize: canopySize,
      altitude: altitude,
      maneuver: maneuver,
      groupSize: groupSize,
    })
      .then(() => {
        setSuccessSnack(true)
        setCurrentDid(-1)
        setEditIndex(-1)
        setDate('')
        setAircraft('')
        setCanopyType('')
        setCanopySize('')
        setAltitude('')
        setManeuver('')
        setGroupSize('')
      })
      .catch((error) => {
        console.log(error)
        setErrorSnack(true)
      })
  }

  const handleChange = (e, name, i) => {
    const { value } = e.target

    if (name === 'aircraft') {
      setAircraft(value)
    } else if (name === 'canopyType') {
      setCanopyType(value)
    } else if (name === 'canopySize') {
      setCanopySize(value)
    } else if (name === 'date') {
      setDate(value)
    } else if (name === 'altitude') {
      setAltitude(value)
    } else if (name === 'maneuver') {
      setManeuver(value)
    } else if (name === 'groupSize') {
      setGroupSize(value)
    }
  }

  return (
    <Paper className={classes.root}>
      {verified ? (
        <>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label='sticky table' size='small'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {logbook.length === 0 ? (
                <TableBody>
                  {emptyRows.map((row) => {
                    return (
                      <TableRow hover role='checkbox' key={row.jumpNr}>
                        {columns.map((column) => {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Skeleton variant='text' />
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const currentlyEditing = editIndex === i
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={-1}
                          key={row.did}
                        >
                          {columns.map((column) => {
                            const value = row[column.id]
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === 'signature' ? (
                                  smallOrBigSignature(value)
                                ) : !currentlyEditing ||
                                  column.id === 'location' ||
                                  column.id === 'jumpNr' ? (
                                  value
                                ) : (
                                  <>
                                    {column.id !== 'canopy' && (
                                      <TextField
                                        select={
                                          column.id === 'aircraft' ||
                                          column.id === 'maneuver'
                                        }
                                        variant='standard'
                                        margin='none'
                                        value={
                                          column.id === 'altitude'
                                            ? altitude
                                            : column.id === 'date'
                                            ? date
                                            : column.id === 'aircraft'
                                            ? aircraft
                                            : column.id === 'maneuver'
                                            ? maneuver
                                            : column.id === 'groupSize'
                                            ? groupSize
                                            : ''
                                        }
                                        type={
                                          column.id === 'altitude' ||
                                          column.id === 'groupSize'
                                            ? 'number'
                                            : ''
                                        }
                                        onChange={(e) =>
                                          handleChange(e, column.id, i)
                                        }
                                      >
                                        {column.id === 'maneuver'
                                          ? maneuverOptions.map((option) => (
                                              <MenuItem
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </MenuItem>
                                            ))
                                          : column.id === 'aircraft'
                                          ? aircraftOptions.map((option) => (
                                              <MenuItem
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </MenuItem>
                                            ))
                                          : ''}
                                      </TextField>
                                    )}
                                    {column.id === 'canopy' && (
                                      <>
                                        <TextField
                                          variant='standard'
                                          margin='none'
                                          value={canopyType}
                                          onChange={(e) =>
                                            handleChange(e, 'canopyType', i)
                                          }
                                        />
                                        <TextField
                                          variant='standard'
                                          margin='none'
                                          value={canopySize}
                                          onChange={(e) =>
                                            handleChange(e, 'canopySize', i)
                                          }
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </TableCell>
                            )
                          })}
                          <TableCell>
                            {currentlyEditing ? (
                              <IconButton
                                size='small'
                                onClick={() => stopEditing()}
                              >
                                <SaveIcon fontSize='small' />
                              </IconButton>
                            ) : editIndex === -1 ? (
                              <IconButton
                                size='small'
                                onClick={() =>
                                  startEditing(
                                    i,
                                    row.did,
                                    row.date,
                                    row.aircraft,
                                    row.canopyType,
                                    row.canopySize,
                                    row.altitude,
                                    row.maneuver,
                                    row.groupSize
                                  )
                                }
                              >
                                <EditIcon fontSize='small' />
                              </IconButton>
                            ) : (
                              <Skeleton
                                variant='circular'
                                width={30}
                                height={30}
                                animation='wave'
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[14, 50, 100, { label: 'All', value: -1 }]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography variant='h5' component='div'>
              Please verify your email!
            </Typography>
            <Typography variant='body2'>
              Check your spam folder or refresh the page
            </Typography>
          </CardContent>
          <CardActions>
            <Button size='small' onClick={resendVerifyEmail}>
              Send new verification email
            </Button>
          </CardActions>
        </Card>
      )}
      <Snackbar
        open={successSnack}
        autoHideDuration={1500}
        onClose={handleCloseSnack}
        sx={{ mb: '50px' }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity='success'
          sx={{ width: '100%' }}
        >
          Jump updated successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnack}
        autoHideDuration={1500}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity='error'
          sx={{ width: '100%' }}
        >
          An error occured!
        </Alert>
      </Snackbar>
    </Paper>
  )
}

export default List
