import React, { useEffect } from 'react'

import { Route, Routes, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import Home from './components/Home'
import Login from './components/Login'

function App(props) {
  const { isAuthenticated } = props

  let navigate = useNavigate()
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home')
    }

    if (!isAuthenticated) {
      navigate('/login')
    }
  }, [isAuthenticated, navigate])

  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  )
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  }
}

export default connect(mapStateToProps)(App)
