import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import AddIcon from '@mui/icons-material/Add'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import List from './List'
import Settings from './Settings'
import AddJump from './AddJump'
import Person from '@mui/icons-material/Person'
import Profile from './Profile'
import { BarChart } from '@mui/icons-material'

function Home(props) {
  const [value, setValue] = useState(0)
  const { isAuthenticated, isLoggingOut, logoutError } = props

  let navigate = useNavigate()
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home')
    }

    if (!isAuthenticated) {
      navigate('/login')
    }
  }, [isAuthenticated, navigate])

  return (
    <div>
      {value === 0 ? <List /> : <></>}
      {value === 1 ? <AddJump /> : <></>}
      {value === 2 ? <Profile /> : <></>}
      {value === 3 ? <Settings /> : <></>}

      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1100 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(_, newValue) => {
            setValue(newValue)
          }}
        >
          <BottomNavigationAction label="Logbook" icon={<LibraryBooksIcon />} />
          <BottomNavigationAction label="Add Jump" icon={<AddIcon />} />
          <BottomNavigationAction label="Profile" icon={<Person />} />
          <BottomNavigationAction label="Statistics" icon={<BarChart />} />
        </BottomNavigation>
      </Paper>
      {isLoggingOut && <p>Logging Out....</p>}
      {logoutError && <p>Error logging out</p>}
    </div>
  )
}
function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError,
  }
}
export default connect(mapStateToProps)(Home)
