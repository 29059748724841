import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth'
import { db } from '../firebase/firebase'
import { ref, set } from 'firebase/database'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const VERIFY_REQUEST = 'VERIFY_REQUEST'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  }
}

const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  }
}

const loginError = () => {
  return {
    type: LOGIN_FAILURE,
  }
}

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  }
}

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  }
}

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  }
}

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  }
}

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  }
}

export const loginUser = (email, password) => (dispatch) => {
  dispatch(requestLogin())
  const auth = getAuth()
  signInWithEmailAndPassword(auth, email, password)
    .then((response) => {
      dispatch(receiveLogin(response.user))
    })
    .catch((error) => {
      //Do something with the error if you want!
      dispatch(loginError())
    })
}

export const registerWithEmailAndPassword =
  (name, totalJumps, email, password) => (dispatch) => {
    dispatch(requestLogin())
    const auth = getAuth()
    createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        const user = response.user
        const userId = user.uid
        const dbref = ref(db, 'users/' + userId)
        set(dbref, {
          name: name,
          totalJumps: totalJumps,
        })
        dispatch(receiveLogin(response.user))
      })
      .then(() => {
        resendVerifyEmail()
      })
      .catch((error) => {
        console.log(error)
        //Do something with the error if you want!
        dispatch(loginError())
      })
  }

export const resendVerifyEmail = () => {
  const auth = getAuth()
  const user = auth.currentUser
  sendEmailVerification(user)
  console.log('email verification sent')
}

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout())
  const auth = getAuth()
  signOut(auth)
    .then(() => {
      dispatch(receiveLogout())
    })
    .catch((error) => {
      //Do something with the error if you want!
      dispatch(logoutError())
    })
}

export const verifyAuth = () => (dispatch) => {
  dispatch(verifyRequest())
  const auth = getAuth()
  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(receiveLogin(user))
    }
    dispatch(verifySuccess())
  })
}
