import React, { useEffect, useState } from 'react'
import { logoutUser } from '../actions'
import { connect } from 'react-redux'
import { push, ref, onValue, remove, set } from 'firebase/database'
import { getAuth } from 'firebase/auth'
import { db } from '../firebase/firebase'

import ClearIcon from '@mui/icons-material/Clear'
import {
  Button,
  Card,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

function Profile(props) {
  const handleLogout = () => {
    const { dispatch } = props
    dispatch(logoutUser())
  }
  const { isLoggingOut, logoutError } = props
  const [dz, setDz] = useState('')
  const [dzList, setDzList] = useState([])
  const [aircraft, setAircraft] = useState('')
  const [aircraftList, setAircraftList] = useState([])
  const [man, setMan] = useState([])
  const [manList, setManList] = useState([])
  const [canopySize, setCanopySize] = useState('')
  const [canopyType, setCanopyType] = useState('')
  const [tempCanopyType, setTempCanopyType] = useState('')
  const [tempCanopySize, setTempCanopySize] = useState('')

  const auth = getAuth()
  const userId = auth.currentUser?.auth?.currentUser?.uid

  const addDropzone = () => {
    const dbref = ref(db, 'users/' + userId + '/dropzones/')
    push(dbref, dz).then(setDz(''))
  }

  const removeDz = (key) => {
    if (key !== '') {
      const dbref = ref(db, 'users/' + userId + '/dropzones/' + key)
      remove(dbref)
    }
  }

  const addManeuver = () => {
    const dbref = ref(db, 'users/' + userId + '/maneuvers/')
    push(dbref, man).then(setMan(''))
  }
  const removeManeuver = (key) => {
    if (key !== '') {
      const dbref = ref(db, 'users/' + userId + '/maneuvers/' + key)
      remove(dbref)
    }
  }

  const addAircraft = () => {
    const dbref = ref(db, 'users/' + userId + '/aircrafts/')
    push(dbref, aircraft).then(setAircraft(''))
  }

  const removeAircraft = (key) => {
    if (key !== '') {
      const dbref = ref(db, 'users/' + userId + '/aircrafts/' + key)
      remove(dbref)
    }
  }

  const updateCanopy = () => {
    const dbref = ref(db, '/users/' + userId + '/canopytype')
    set(dbref, tempCanopyType).then(setTempCanopyType(''))
    const dbref2 = ref(db, '/users/' + userId + '/canopysize')
    set(dbref2, tempCanopySize).then(setTempCanopySize(''))
  }

  useEffect(() => {
    const getDropzones = () => {
      const dbref = ref(db, '/users/' + userId + '/dropzones')
      onValue(dbref, (snapshot) => {
        const data = snapshot.val()
        if (data !== null) {
          setDzList(data)
        }
      })
    }

    const getManeuvers = () => {
      const dbref = ref(db, '/users/' + userId + '/maneuvers')
      onValue(dbref, (snapshot) => {
        const data = snapshot.val()
        if (data !== null) {
          setManList(data)
        }
      })
    }

    const getAircrafts = () => {
      const dbref = ref(db, '/users/' + userId + '/aircrafts')
      onValue(dbref, (snapshot) => {
        const data = snapshot.val()
        if (data !== null) {
          setAircraftList(data)
        }
      })
    }

    const getCanopy = () => {
      const dbref = ref(db, '/users/' + userId + '/canopytype')
      onValue(dbref, (snapshot) => {
        const data = snapshot.val()
        if (data !== null) {
          setCanopyType(data)
        }
      })
      const dbref2 = ref(db, '/users/' + userId + '/canopysize')
      onValue(dbref2, (snapshot) => {
        const data = snapshot.val()
        if (data !== null) {
          setCanopySize(data)
        }
      })
    }

    getDropzones()
    getAircrafts()
    getCanopy()
    getManeuvers()
  }, [userId])

  return (
    <Container>
      <Stack
        direction="column"
        spacing={3}
        justifyContent="space-evenly"
        alignItems="center"
        marginBottom={15}
      >
        <Paper sx={{ width: '100%' }}>
          <Typography sx={{ mt: 2, ml: 2 }} variant="h6" component="div">
            Dropzones
          </Typography>
          <List dense>
            {Object.keys(dzList).map((i) => {
              return (
                <ListItem
                  key={i}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeDz(i)}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={dzList[i]} />
                </ListItem>
              )
            })}
          </List>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            padding={2}
          >
            <TextField
              fullWidth
              size="small"
              id="outlined-basic"
              label="New Dropzone"
              variant="outlined"
              value={dz}
              onChange={(e) => setDz(e.target.value)}
            />
            <Button variant="contained" onClick={addDropzone}>
              Add
            </Button>
          </Stack>
        </Paper>
        <Paper sx={{ width: '100%' }}>
          <Typography sx={{ mt: 2, ml: 2 }} variant="h6" component="div">
            Aircrafts
          </Typography>
          <List dense>
            {Object.keys(aircraftList).map((i) => {
              return (
                <ListItem
                  key={i}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeAircraft(i)}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={aircraftList[i]} />
                </ListItem>
              )
            })}
          </List>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            padding={2}
          >
            <TextField
              fullWidth
              size="small"
              id="outlined-basic"
              label="New Aircraft"
              variant="outlined"
              value={aircraft}
              onChange={(e) => setAircraft(e.target.value)}
            />
            <Button variant="contained" onClick={addAircraft}>
              Add
            </Button>
          </Stack>
        </Paper>
        <Paper sx={{ width: '100%' }}>
          <Typography sx={{ mt: 2, ml: 2 }} variant="h6" component="div">
            Maneuvers
          </Typography>
          <List dense>
            {Object.keys(manList).map((i) => {
              return (
                <ListItem
                  key={i}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeManeuver(i)}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={manList[i]} />
                </ListItem>
              )
            })}
          </List>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            padding={2}
          >
            <TextField
              fullWidth
              size="small"
              id="outlined-basic"
              label="New Maneuver"
              variant="outlined"
              value={man}
              onChange={(e) => setMan(e.target.value)}
            />
            <Button variant="contained" onClick={addManeuver}>
              Add
            </Button>
          </Stack>
        </Paper>
        <Paper sx={{ width: '100%' }}>
          <Typography sx={{ mt: 2, ml: 2 }} variant="h6" component="div">
            Canopy
          </Typography>
          <Typography sx={{ mt: 2, ml: 2 }} component="div">
            {canopyType + ' ' + canopySize}
          </Typography>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            padding={2}
          >
            <TextField
              fullWidth
              size="small"
              id="outlined-basic"
              label="Canopy Type"
              variant="outlined"
              value={tempCanopyType}
              onChange={(e) => setTempCanopyType(e.target.value)}
            />
            <TextField
              fullWidth
              size="small"
              id="outlined-basic"
              label="Canopy Size"
              variant="outlined"
              value={tempCanopySize}
              onChange={(e) => setTempCanopySize(e.target.value)}
            />
            <Button variant="contained" onClick={updateCanopy}>
              Update
            </Button>
          </Stack>
        </Paper>
        <Card>
          <Button variant="contained" color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </Card>
        {isLoggingOut && <p>Logging Out....</p>}
        {logoutError && <p>Error logging out</p>}
      </Stack>
    </Container>
  )
}
function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError,
  }
}
export default connect(mapStateToProps)(Profile)
