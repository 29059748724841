import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'

import { getAuth } from 'firebase/auth'
import { ref, onValue } from 'firebase/database'
import { db } from '../firebase/firebase'
import { Divider } from '@mui/material'

function Statistics() {
  const [series, setSeries] = useState([])
  const [datelabel, setDatelabel] = useState([])

  const [years, setYears] = useState([])
  const [planes, setPlanes] = useState([])
  const [canopies, setCanopies] = useState([])
  const [dropzones, setDropzones] = useState([])
  const [maneuvers, setManeuvers] = useState([])

  const auth = getAuth()
  const userId = auth.currentUser?.auth?.currentUser?.uid

  useEffect(() => {
    const updateBoard = (data) => {
      const temp = []
      const temp2 = []
      const temp3 = []
      const temp4 = []
      const temp5 = []
      const temp6 = []
      const mans = []
      const dates = []

      for (const jump in data) {
        if (Object.hasOwnProperty.call(data, jump)) {
          const element = data[jump]
          // Count jumps pr year
          if (
            temp.some((val) => {
              return val['x'] === element.date.substring(6)
            })
          ) {
            temp.forEach((k) => {
              if (k['x'] === element.date.substring(6)) {
                k['y']++
              }
            })
          } else {
            let a = {}
            a['x'] = element.date.substring(6)
            a['y'] = 1
            temp.push(a)
          }

          // Count maneuvers pr month
          if (!mans.includes(element.maneuver)) {
            mans.push(element.maneuver)
          }
          if (!dates.includes(element.date.substring(3))) {
            dates.push(element.date.substring(3))
          }
          if (
            temp2.some((val) => {
              return (
                val['x'] === element.date.substring(3) &&
                val['name'] === element.maneuver
              )
            })
          ) {
            temp2.forEach((k) => {
              if (
                k['x'] === element.date.substring(3) &&
                k['name'] === element.maneuver
              ) {
                k['y']++
              }
            })
          } else {
            let a = {}
            a['x'] = element.date.substring(3)
            a['name'] = element.maneuver
            a['y'] = 1
            temp2.push(a)
          }

          // Count jumps pr aircraft
          if (
            temp3.some((val) => {
              return val['x'] === element.aircraft
            })
          ) {
            temp3.forEach((k) => {
              if (k['x'] === element.aircraft) {
                k['y']++
              }
            })
          } else {
            let a = {}
            a['x'] = element.aircraft
            a['y'] = 1
            temp3.push(a)
          }

          // Count jumps pr aircraft
          if (
            temp6.some((val) => {
              return val['x'] === element.maneuver
            })
          ) {
            temp6.forEach((k) => {
              if (k['x'] === element.maneuver) {
                k['y']++
              }
            })
          } else {
            let a = {}
            a['x'] = element.maneuver
            a['y'] = 1
            temp6.push(a)
          }

          // Count jumps pr canopy type/size
          if (
            temp4.some((val) => {
              return val['x'] === element.canopyType + ' ' + element.canopySize
            })
          ) {
            temp4.forEach((k) => {
              if (k['x'] === element.canopyType + ' ' + element.canopySize) {
                k['y']++
              }
            })
          } else {
            let a = {}
            a['x'] = element.canopyType + ' ' + element.canopySize
            a['y'] = 1
            temp4.push(a)
          }

          if (
            temp5.some((val) => {
              return val['x'] === element.location
            })
          ) {
            temp5.forEach((k) => {
              if (k['x'] === element.location) {
                k['y']++
              }
            })
          } else {
            let a = {}
            a['x'] = element.location
            a['y'] = 1
            temp5.push(a)
          }
        }
      }
      setYears(temp.sort((a, b) => (a.x > b.x ? 1 : -1)))
      setPlanes(temp3.sort((a, b) => (a.y > b.y ? 1 : -1)))
      setCanopies(temp4.sort((a, b) => (a.y > b.y ? 1 : -1)))
      setDropzones(temp5.sort((a, b) => (a.y > b.y ? 1 : -1)))
      setManeuvers(temp6.sort((a, b) => (a.y > b.y ? 1 : -1)))

      temp2.sort(function (a, b) {
        const c = a.x.split('-').reverse().join('')
        const d = b.x.split('-').reverse().join('')
        return c > d ? 1 : c < d ? -1 : 0
      })
      setDatelabel(
        dates.sort(function (a, b) {
          const c = a.split('-').reverse().join('')
          const d = b.split('-').reverse().join('')
          return c > d ? 1 : c < d ? -1 : 0
        })
      )
      const temps = []
      for (const m in mans) {
        if (Object.hasOwnProperty.call(mans, m)) {
          const melement = mans[m]
          const mcount = []

          for (const d in dates) {
            if (Object.hasOwnProperty.call(dates, d)) {
              const delement = dates[d]

              if (
                temp2.some((val) => {
                  return val['x'] === delement && val['name'] === melement
                })
              ) {
                temp2.forEach((k) => {
                  if (k['x'] === delement && k['name'] === melement) {
                    mcount.push(k['y'])
                  }
                })
              } else {
                mcount.push(0)
              }
            }
          }
          temps.push({ name: melement, data: mcount })
        }
      }
      setSeries(temps)
    }
    const userRef = ref(db, '/users/' + userId + '/logbook/')
    onValue(userRef, (snapshot) => {
      const data = snapshot.val()
      if (data !== null) {
        if (series.length === 0 && datelabel.length === 0) {
          updateBoard(data)
        }
      }
    })
  }, [userId, series.length, years, datelabel.length])

  const opts = {
    chart: {
      stacked: true,
      type: 'bar',
    },
    xaxis: {
      categories: datelabel,
    },
    yaxis: {
      max: function (max) {
        return max
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    title: {
      text: 'Jumps of type pr Month',
    },
  }

  const opts2 = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    title: {
      text: 'Jumps pr Year',
    },
  }
  const opts3 = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    title: {
      text: 'Jumps pr Aircraft',
    },
  }
  const opts6 = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    title: {
      text: 'Jumps pr Maneuver',
    },
  }
  const opts4 = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    title: {
      text: 'Jumps pr Canopy',
    },
  }
  const opts5 = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    title: {
      text: 'Jumps pr Dropzone',
    },
  }
  const newYseries = [
    {
      data: years,
    },
  ]
  const newYseries6 = [
    {
      data: maneuvers,
    },
  ]
  const newYseries3 = [
    {
      data: planes,
    },
  ]
  const newYseries4 = [
    {
      data: canopies,
    },
  ]
  const newYseries5 = [
    {
      data: dropzones,
    },
  ]
  return (
    <>
      {years !== [] ? (
        <Chart options={opts2} series={newYseries} type='bar' />
      ) : (
        <></>
      )}
      <Divider />
      {maneuvers !== [] ? (
        <Chart options={opts6} series={newYseries6} type='bar' />
      ) : (
        <></>
      )}
      <Divider />
      {series !== [] ? (
        <Chart options={opts} series={series} type='bar' />
      ) : (
        <></>
      )}
      <Divider />
      {planes !== [] ? (
        <Chart options={opts3} series={newYseries3} type='bar' />
      ) : (
        <></>
      )}
      <Divider />
      {canopies !== [] ? (
        <Chart options={opts4} series={newYseries4} type='bar' />
      ) : (
        <></>
      )}
      <Divider />
      {dropzones !== [] ? (
        <Chart options={opts5} series={newYseries5} type='bar' />
      ) : (
        <></>
      )}
    </>
  )
}
export default Statistics
