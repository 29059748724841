import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Modal,
  Card,
  Snackbar,
  Alert,
  Stack,
  IconButton,
  Divider,
  MenuItem,
} from '@mui/material'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateAdapter from '@mui/lab/AdapterMoment'
import { withStyles } from '@mui/styles'
import moment from 'moment'
import SignaturePad from 'react-signature-pad-wrapper'
import { push, ref, onValue, update } from 'firebase/database'
import { getAuth } from 'firebase/auth'
import { db } from '../firebase/firebase'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
}

const styles = () => ({
  main: {
    backgroundColor: 'white',
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 100,
  },
  save: {
    top: 20,
  },
  img: {
    width: '90%',
    height: '90%',
  },
  divider: {
    padding: 5,
  },
})

function AddJump(props) {
  const { classes } = props

  const [jumpNr, setJumpNr] = useState('')
  const [date, setDate] = useState(moment())
  const [location, setLocation] = useState('')
  const [aircraft, setAircraft] = useState('')
  const [canopyType, setCanopyType] = useState('')
  const [canopySize, setCanopySize] = useState('')
  const [altitude, setAltitude] = useState('')
  const [maneuver, setManeuver] = useState('')
  const [groupSize, setGroupSize] = useState('')
  const [signature, setSignature] = useState('')
  const [multiJump, setMultiJump] = useState(1)
  const [totalJumps, setTotalJumps] = useState('')

  const [aircraftOptions, setAircraftOptions] = useState([])
  const [dropzoneOptions, setDropzoneOptions] = useState([])
  const [maneuverOptions, setManeuverOptions] = useState([])

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [errorSnack, setErrorSnack] = useState(false)
  const [successSnack, setSuccessSnack] = useState(false)
  const [loading, setLoading] = useState(false)

  const signaturePadRef = React.createRef()

  const auth = getAuth()
  const userId = auth.currentUser?.auth?.currentUser?.uid

  const required =
    jumpNr === '' ||
    date === '' ||
    location === '' ||
    aircraft === '' ||
    canopyType === '' ||
    canopySize === '' ||
    altitude === '' ||
    maneuver === '' ||
    groupSize === '' ||
    signature === ''

  const handleClear = () => {
    const signaturePad = signaturePadRef.current
    if (signaturePad) {
      signaturePad.clear()
    }
  }
  const handleSaveSignature = () => {
    const signaturePad = signaturePadRef.current
    if (signaturePad) {
      setSignature(signaturePad.toDataURL())
      handleClose()
    }
  }
  const handleCloseSnack = (event, reason) => {
    setSuccessSnack(false)
    setErrorSnack(false)
  }

  const handleSubmit = () => {
    setLoading(true)
    const formatDate = moment(date).format('DD-MM-YYYY')

    try {
      for (var i = 0; i < multiJump; i++) {
        const jumpObj = {
          jumpNr: (parseInt(jumpNr) + i).toString(),
          location: location,
          aircraft: aircraft,
          date: formatDate,
          altitude: altitude,
          maneuver: maneuver,
          groupSize: groupSize,
          canopyType: canopyType,
          canopySize: canopySize,
          signature: signature,
        }
        const dbref = ref(db, 'users/' + userId + '/logbook/')
        push(dbref, jumpObj)
      }

      if (parseInt(totalJumps) < parseInt(jumpNr) + parseInt(multiJump) - 1) {
        const dbref = ref(db, 'users/' + userId)
        update(dbref, {
          totalJumps: (parseInt(jumpNr) + parseInt(multiJump) - 1).toString(),
        })
      }
      setSuccessSnack(true)
      setDate(moment())
      setLocation('')
      setAircraft('')
      setAltitude('')
      setManeuver('')
      setGroupSize('')
      setSignature('')
      setLoading(false)
      setMultiJump(1)
    } catch (error) {
      setLoading(false)
      setErrorSnack(true)
    }
  }

  const increaseMultiJump = () => {
    setMultiJump(multiJump + 1)
  }
  const decreaseMultiJump = () => {
    if (multiJump > 1) {
      setMultiJump(multiJump - 1)
    }
  }

  useEffect(() => {
    const getTotalJumps = () => {
      const userRef = ref(db, '/users/' + userId + '/totalJumps')
      onValue(userRef, (snapshot) => {
        const data = snapshot.val()
        if (data !== null) {
          setTotalJumps(data)
          setJumpNr(parseInt(data) + 1)
        }
      })
    }

    const getAircraftOptions = () => {
      const dbref = ref(db, '/users/' + userId + '/aircrafts')
      onValue(dbref, (snapshot) => {
        const data = snapshot.val()
        const temp = []
        if (data !== null) {
          Object.values(data).map((i) => temp.push(i))
          setAircraftOptions(temp)
        }
      })
    }

    const getDropzoneOptions = () => {
      const dbref = ref(db, '/users/' + userId + '/dropzones')
      onValue(dbref, (snapshot) => {
        const data = snapshot.val()
        const temp = []
        if (data !== null) {
          Object.values(data).map((i) => temp.push(i))
          setDropzoneOptions(temp)
        }
      })
    }

    const getManeuverOptions = () => {
      const dbref = ref(db, '/users/' + userId + '/maneuvers')
      onValue(dbref, (snapshot) => {
        const data = snapshot.val()
        const temp = []
        if (data !== null) {
          Object.values(data).map((i) => temp.push(i))
          setManeuverOptions(temp)
        }
      })
    }

    const getCanopy = () => {
      const dbref = ref(db, '/users/' + userId + '/canopytype')
      onValue(dbref, (snapshot) => {
        const data = snapshot.val()
        if (data !== null) {
          setCanopyType(data)
        }
      })
      const dbref2 = ref(db, '/users/' + userId + '/canopysize')
      onValue(dbref2, (snapshot) => {
        const data = snapshot.val()
        if (data !== null) {
          setCanopySize(data)
        }
      })
    }

    getTotalJumps()
    getAircraftOptions()
    getDropzoneOptions()
    getManeuverOptions()
    getCanopy()
  }, [userId])

  return (
    <Container component="main" className={classes.main}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Typography component="h1" variant="h5">
          Add Jump
        </Typography>
        <Divider className={classes.divider} />
        <Stack
          direction="row"
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="subtitle1">Nr of jumps:</Typography>
          <Typography variant="subtitle1">{multiJump}</Typography>
          <IconButton
            aria-label="fingerprint"
            onClick={increaseMultiJump}
            size="large"
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            aria-label="fingerprint"
            onClick={decreaseMultiJump}
            size="large"
          >
            <RemoveIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="jumpNr"
          label="Jump Number"
          name="jumpNr"
          type="number"
          value={jumpNr}
          onChange={(e) => setJumpNr(e.target.value)}
        />
        <MobileDatePicker
          label="Date"
          inputFormat="DD-MM-YYYY"
          value={date}
          onChange={(v) => {
            setDate(v)
          }}
          renderInput={(params) => (
            <TextField fullWidth margin="normal" {...params} />
          )}
        />
        <TextField
          select
          variant="outlined"
          margin="normal"
          fullWidth
          id="location"
          label="Location"
          name="location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        >
          {dropzoneOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          variant="outlined"
          margin="normal"
          fullWidth
          id="aircraft"
          label="Aircraft"
          name="aircraft"
          value={aircraft}
          onChange={(e) => setAircraft(e.target.value)}
        >
          {aircraftOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
          id="canopyType"
          label="Canopy Type"
          name="canopyType"
          value={canopyType}
          onChange={(e) => setCanopyType(e.target.value)}
        />
        <TextField
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
          id="canopySize"
          label="Canopy Size"
          name="canopysize"
          type="number"
          value={canopySize}
          onChange={(e) => setCanopySize(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="altitude"
          label="Altitude"
          name="altitude"
          type="number"
          value={altitude}
          onChange={(e) => setAltitude(e.target.value)}
        />
        <TextField
          select
          variant="outlined"
          margin="normal"
          fullWidth
          id="maneuver"
          label="Maneuver"
          name="maneuver"
          value={maneuver}
          onChange={(e) => setManeuver(e.target.value)}
        >
          {maneuverOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="groupSize"
          label="Group Size"
          name="groupsize"
          type="number"
          value={groupSize}
          onChange={(e) => setGroupSize(e.target.value)}
        />
        {signature !== '' ? (
          <Card variant="outlined">
            <img className={classes.img} src={signature} alt="signature" />
          </Card>
        ) : (
          <></>
        )}
        <div>
          <Button onClick={handleOpen}>Open Signature</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <SignaturePad ref={signaturePadRef} />
              <div>
                <Button type="button" onClick={handleClear}>
                  Clear
                </Button>
                <Button type="button" onClick={handleSaveSignature}>
                  Save
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
        {loading ? (
          <LoadingButton
            type="button"
            variant="contained"
            fullWidth
            loading
            className={classes.save}
          >
            Save Jump
          </LoadingButton>
        ) : (
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.save}
            onClick={handleSubmit}
            disabled={required}
          >
            Save Jump
          </Button>
        )}
      </LocalizationProvider>
      <Snackbar
        open={successSnack}
        autoHideDuration={1500}
        onClose={handleCloseSnack}
        sx={{ mb: '50px' }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          sx={{ width: '100%' }}
        >
          Jump saved successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnack}
        autoHideDuration={1500}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="error"
          sx={{ width: '100%' }}
        >
          An error occured!
        </Alert>
      </Snackbar>
    </Container>
  )
}
function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated,
  }
}
export default withStyles(styles)(connect(mapStateToProps)(AddJump))
