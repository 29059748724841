import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyCMDGpsSHYbQREqx8iMsz7ZUN3F261Bke4',
  authDomain: 'logbookv2.firebaseapp.com',
  databaseURL:
    'https://logbookv2-default-rtdb.europe-west1.firebasedatabase.app/',
  projectId: 'logbookv2',
  storageBucket: 'logbookv2.appspot.com',
  messagingSenderId: '702044929143',
  appId: '1:702044929143:web:694cf6f3be87ccb8571a72',
  measurementId: 'G-JR6EV5DRGN',
}

export const myFirebase = initializeApp(firebaseConfig)
export const db = getDatabase(myFirebase)
export const analytics = getAnalytics(myFirebase)
