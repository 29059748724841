import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { loginUser, registerWithEmailAndPassword } from '../actions'
import { withStyles } from '@mui/styles'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'

const styles = () => ({
  '@global': {
    body: {
      backgroundColor: '#4270dd',
    },
  },
  paper: {
    marginTop: 100,
    display: 'flex',
    padding: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  paperBanner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#f50057',
  },
  form: {
    marginTop: 1,
  },
  errorText: {
    color: '#f50057',
    marginBottom: 5,
    textAlign: 'center',
  },
})

class Login extends Component {
  state = {
    name: '',
    totalJumps: '',
    email: '',
    code: '',
    password: '',
    signup: false,
  }

  handleNameChange = ({ target }) => {
    this.setState({ name: target.value })
  }

  handleJumpChange = ({ target }) => {
    this.setState({ totalJumps: target.value })
  }

  handleEmailChange = ({ target }) => {
    this.setState({ email: target.value })
  }

  handleCodeChange = ({ target }) => {
    this.setState({ code: target.value })
  }

  handlePasswordChange = ({ target }) => {
    this.setState({ password: target.value })
  }

  handleSignup = () => {
    this.setState({ signup: !this.state.signup })
  }

  handleSubmit = () => {
    const { dispatch } = this.props
    const { name, totalJumps, email, password, signup } = this.state

    if (signup) {
      dispatch(registerWithEmailAndPassword(name, totalJumps, email, password))
    } else {
      dispatch(loginUser(email, password))
    }
  }

  render() {
    const { classes, loginError, isAuthenticated } = this.props
    if (isAuthenticated) {
      return <Navigate to='/home' />
    } else {
      return (
        <Container component='main' maxWidth='xs'>
          <div className={classes.paperBanner}>
            <img
              src='https://jaanesen.no/logbook/android_feature_graphic.png'
              alt='banner'
              style={{ maxWidth: '100%', alignSelf: 'center' }}
            />
          </div>
          <Paper className={classes.paper}>
            <Typography component='h1' variant='h5'>
              {this.state.signup ? 'Sign Up' : 'Sign In'}
            </Typography>
            {this.state.signup ? (
              <>
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='name'
                  label='Full Name'
                  name='name'
                  onChange={this.handleNameChange}
                />
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='totalJumps'
                  label='Total Jumps'
                  name='totalJumps'
                  type='number'
                  onChange={this.handleJumpChange}
                />
              </>
            ) : (
              <></>
            )}
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              onChange={this.handleEmailChange}
            />
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              onChange={this.handlePasswordChange}
            />
            {loginError && (
              <Typography component='p' className={classes.errorText}>
                Incorrect email or password.
              </Typography>
            )}
            {this.state.signup ? (
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                id='code'
                label='Referral Code'
                name='code'
                onChange={this.handleCodeChange}
              />
            ) : (
              <></>
            )}
            <Button
              type='button'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={this.handleSubmit}
              sx={{ marginTop: 2 }}
              disabled={this.state.signup && this.state.code !== 'ÆRA'}
            >
              {this.state.signup ? 'Sign Up' : 'Sign In'}
            </Button>
            {/*  <Button
              type='button'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={this.handleSignup}
              sx={{ marginTop: 2 }}
            >
              Switch to {!this.state.signup ? 'Sign Up' : 'Sign In'}!
            </Button> */}
          </Paper>
        </Container>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Login))
