import React from 'react'
import { Card, Container, Stack } from '@mui/material'
import Statistics from './Statistics'

function Settings() {
  return (
    <Container>
      <Stack
        direction="column"
        spacing={3}
        justifyContent="space-evenly"
        alignItems="center"
        marginBottom={15}
      >
        <Card sx={{ width: '100%', maxWidth: '1000px' }}>
          <Statistics />
        </Card>
      </Stack>
    </Container>
  )
}
export default Settings
